<template>
  <v-col class="custom-rounded-border" cols="12" sm="12">
    <slot name="title">
      <v-row class="custom-titulo">
        <v-col cols="12" sm="12" class="font-weight-bold">
          {{getTitle}} 
        </v-col>
      </v-row>
    </slot>
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: 'CardPersonalizado',
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    getTitle() {
      return this?.title ? this.title : '';
    }
  },  
};
</script>

<style scoped>
.custom-rounded-border {
  border: 1px solid rgba(0, 0, 0, 0.12); /* Similar to v-card border */
  border-radius: 8px;                   /* Border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06); /* Shadow for floating effect */
  /* padding: 16px;                       Padding for inner spacing */
  background-color: white;             /* Background color */
}
.custom-titulo {
  border-radius: 8px 8px 0 0 ;    
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #2EAB66;
  color: white; 
  font-size: 0.9em;
}
</style>
