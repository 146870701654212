<template>
  <div>
    <v-card v-if="elements.length === 0" class="elevation-0">
      <v-card-text class="text-center">
        <p>Nenhuma demanda em negociação foi encontrada.</p>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" md="4" lg="3" v-for="item of elements" :key="item.id" class="ultima-coluna">
        <CardPersonalizado :title="item.demanda.oferta.descricaoTipoItem+' / '+item.demanda.oferta.descricao">
          <v-row dense v-if="nivelUsuarioLogado === 'COM'">                
            <v-col cols="12" sm="12" class="py-3">
              <span class="titulo-detalhe">Fornecedor:</span>
              <br>
              <span class="detalhe">{{item.fornecedor.nomeCompletoSemCnpj}}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="titulo-detalhe">Data Match:</span>
              <br>
              <span class="detalhe">{{item.dataCadastro | moment("DD/MM/YYYY")}}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="titulo-detalhe">Prazo da Demanda:</span>
              <br>
              <span class="detalhe">{{item.demanda.dataExpiracao | moment("DD/MM/YYYY")}}</span>
            </v-col>
            <v-col cols="12" sm="12">
              <span class="titulo-detalhe">Município:</span>
              <br>
              <span class="detalhe">{{item.fornecedor.cidade}}/{{item.fornecedor.estado}}</span>
            </v-col>
          </v-row>
          <v-row dense v-if="nivelUsuarioLogado === 'FOR'">
            <v-col cols="12" sm="12" class="py-3">
              <span class="titulo-detalhe">Demandante:</span>
              <br>
              <span class="detalhe">{{item.demanda.comprador.nomeCompletoSemCnpj}}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="titulo-detalhe">Data Match:</span>
              <br>
              <span class="detalhe">{{item.dataCadastro | moment("DD/MM/YYYY")}}</span>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="titulo-detalhe">Prazo da Demanda:</span>
              <br>
              <span class="detalhe">{{item.demanda.dataExpiracao | moment("DD/MM/YYYY")}}</span>
            </v-col>
            <v-col cols="12" sm="8">
              <span class="titulo-detalhe">Município:</span>
              <br>
              <span class="detalhe">{{item.demanda.comprador.cidade}}/{{item.demanda.comprador.estado}}</span>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="titulo-detalhe">Inscritos:</span>
              <br>
              <span class="detalhe">{{item.demanda.qtdInscritos}}</span>
            </v-col>
          </v-row>          
          <v-row class="borda-footer">
            <v-col align="left" cols="12" sm="6">
              <v-badge
                :content="item.qtdMensagensNaoLidas"
                v-if="parseInt(item.qtdMensagensNaoLidas) > 0"
                color="red"
                overlap>
                <v-btn rounded color="#167ABC" style="color:white" @click="item.dialogChat = true">
                  <v-icon left>mdi-chat-outline</v-icon> Chat
                </v-btn>
              </v-badge>
              <v-btn v-else rounded color="#167ABC" style="color:white" @click="item.dialogChat = true">
                <v-icon left>mdi-chat-outline</v-icon> Chat
              </v-btn>
            </v-col>
            <v-col align="right" cols="12" sm="6">
              <v-btn rounded outlined color="#167ABC" @click="item.dialogDetalhe = true">Detalhes</v-btn>
            </v-col>
          </v-row>
        </CardPersonalizado>
        <DetalheDemanda :item="item" />
        <ChatMatch :item="item" />
      </v-col>
      <v-col cols="12" class align="right">
        <div class="text-xs-center">
          <v-pagination
            v-model="pageOptions.page"
            :length="pageOptions.totalPages"
            :total-visible="7"
            circle
            :disabled="loading || totalElements <= 0"
            @input="onPageChange"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {findAll} from '../../../api/deuMatch';
import ResponsiveMenu from "../../app/ResponsiveMenu";
import {ApiError} from "../../../api/core";
import CardPersonalizado from "../../../components/shared/common/CardPersonalizado";
import ChatMatch from "../../../components/shared/common/ChatMatch";
import DetalheDemanda from "../../../components/shared/common/DetalheDemanda";
import {mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: "DemandaPainelDataTable",
  components: {ResponsiveMenu, CardPersonalizado, ChatMatch, DetalheDemanda},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      pageOptions: {
        page: 1,
        totalPages: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  methods: {
    onPageChange(page) {
      this.refresh();
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;
        this.pageOptions.totalPages = page.totalPages;

      } catch (err) {
        this.error = err;
        this.elements = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar a lista de Demandas em negociação.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  },
}
</script>

<style scoped lang="scss">
.ultima-coluna {
  margin-bottom: 10px;
}

.titulo-detalhe {
  font-size: 0.7em;
  color:#979393;
}

.detalhe {
  font-size: 0.9em;
  font-weight: bold;
  color:#167ABC;
}
.borda-footer {
  border-top: 1px solid #E7E7E7;
}
.custom-row .col-12 {
  border:1px solid red;
}
</style>