"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findGraficoDemandaVsMatch = exports.findGraficoComprador = exports.findGraficoFornecedor = void 0;
const core_1 = require("./core");
function findGraficoFornecedor(page = 1, search) {
    return core_1.get('VwPainel', 'graficoFornecedor', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findGraficoFornecedor = findGraficoFornecedor;
function findGraficoComprador(page = 1, search) {
    return core_1.get('VwPainel', 'graficoComprador', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findGraficoComprador = findGraficoComprador;
function findGraficoDemandaVsMatch(page = 1, search) {
    return core_1.get('VwPainel', 'graficoDemandaVsMatch', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findGraficoDemandaVsMatch = findGraficoDemandaVsMatch;
// export function findSaldoIntegracaoZap(page: number = 1, search?: FindAllSearch): Promise<FindAllResponse> {
//     return get('VwPainel','consultaSaldoIntegracaoZap', {pg: page, ...search})
//         .then(({dados, paginacao}) => {
//             if(dados.length === 0) return {
//                 elements: [],
//                 elementsPerPage: 0,
//                 totalElements: 0,
//                 currentPage: 0,
//                 totalPages: 0
//             }
//             return {
//                 elements: dados,
//                 elementsPerPage: paginacao.totalRegPorPagina,
//                 totalElements: paginacao.totalRegEncontrados,
//                 currentPage: paginacao.pagAtual,
//                 totalPages: paginacao.qtdPag
//             }
//         })
// }
// export function findStatusIntegracaoZap(page: number = 1, search?: FindAllSearch): Promise<FindAllResponse> {
//     return get('VwPainel','consultaStatusIntegracaoZap', {pg: page, ...search})
//         .then(({dados, paginacao}) => {
//             if(dados.length === 0) return {
//                 elements: [],
//                 elementsPerPage: 0,
//                 totalElements: 0,
//                 currentPage: 0,
//                 totalPages: 0
//             }
//             return {
//                 elements: dados,
//                 elementsPerPage: paginacao.totalRegPorPagina,
//                 totalElements: paginacao.totalRegEncontrados,
//                 currentPage: paginacao.pagAtual,
//                 totalPages: paginacao.qtdPag
//             }
//         })
// }
