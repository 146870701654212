<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          v-model="item.dialogDetalhe" style="border-radius: 8px;"
          scrollable max-width="1000px"
          persistent>
        <v-card class="d-flex flex-column">
          <v-card-title class="primary white--text">
            <v-row>
              <v-col cols="12" md="10">
                {{getTitle}}
              </v-col>
              <v-col cols="12" align="right" md="2">
                <v-btn text elevation="0" color="white" small @click="fecharDetalhe()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider/>
          <v-card-text class="py-4 flex-grow-1" 
            style="height: 80%;">          
              <v-row v-if="souUmMatch">
                <v-col cols="12" md="4">
                  <div class="titulo">Data Cadastro</div>
                  <div class="detalhe">{{item.demanda.dataCadastro | moment("DD/MM/YYYY")}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Data Expiração</div>
                  <div class="detalhe">{{item.demanda.dataExpiracao | moment("DD/MM/YYYY")}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Qtd Inscritos</div>
                  <div class="detalhe">{{item.demanda.qtdInscritos}}</div>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="titulo">Descrição da Demanda</div>
                  <div class="detalhe">{{item.demanda.descricao}}</div>
                </v-col>

                <v-col cols="12" md="8">
                  <div class="titulo">Fornecedor</div>
                  <div class="detalhe">{{item.fornecedor.nomeCompletoSemCnpj}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Cidade/Estado</div>
                  <div class="detalhe">{{item.fornecedor.cidade+'/'+item.fornecedor.estado}}</div>
                </v-col>
                <v-col cols="12" md="8">
                  <div class="titulo">Comprador</div>
                  <div class="detalhe">{{item.demanda.comprador.nomeCompletoSemCnpj}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Cidade/Estado</div>
                  <div class="detalhe">{{item.demanda.comprador.cidade+'/'+item.demanda.comprador.estado}}</div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="4">
                  <div class="titulo">Data Cadastro</div>
                  <div class="detalhe">{{item.dataCadastro | moment("DD/MM/YYYY")}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Data Expiração</div>
                  <div class="detalhe">{{item.dataExpiracao | moment("DD/MM/YYYY")}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Qtd Inscritos</div>
                  <div class="detalhe">{{item.qtdInscritos}}</div>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="titulo">Descrição da Demanda</div>
                  <div class="detalhe">{{item.descricao}}</div>
                </v-col>

                <v-col cols="12" md="8">
                  <div class="titulo">Comprador</div>
                  <div class="detalhe">{{item.descricaoCompradorSemCnpj}}</div>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="titulo">Cidade/Estado</div>
                  <div class="detalhe">{{item.cidade+'/'+item.estado}}</div>
                </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import moment from 'moment';

export default {
  name: 'DetalheDemanda',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },  
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
    getTitle() {
      return 'Detalhes da demanda: ' + (this.item?.descricaoOferta ? 
              this.item.descricaoTipoItemOferta +'/'+ this.item.descricaoOferta : 
              this.item.demanda.oferta.descricaoTipoItem +'/'+ this.item.demanda.oferta.descricao);
    },
    souUmMatch(){
      return parseInt(this.item.fornecedor?.id) > 0;
    }
  },  
  methods: {
    fecharDetalhe(){
      this.item.dialogDetalhe = false;
    },
  },
  created() {
  },
  beforeDestroy() {
  }
};
</script>

<style scoped>
  .titulo {
    font-size: 1em;
    font-weight: bold;
    color: black;
  }

</style>
